import React from 'react';
import './Hero.css';

const Hero = () => {
  return (
    <section id="hero" className="hero">
      <div className="hero-content">
        <h1>Alexander T. Bakker</h1>
        <h2>Emerging Machine Learning Engineer | Former Chemical Biologist | Hobbyist Nerd</h2>
        <p>Actively trying to turn the chaos in my head into digestible knowledge, wisdom, fun and games.</p>
        <button className="cta-button">View My Work</button>
      </div>
    </section>
  );
};

export default Hero;