import React from 'react';
import './Projects.css';

const Projects = () => {
  return (
    <section id="projects" className="projects">
      <h2>Projects</h2>
      <div className="project-grid">
        {/* Add your project cards here */}
        {/* <div className="project-card">Project 1</div>
        <div className="project-card">Project 2</div>
        <div className="project-card">Project 3</div> */}
        <p>Coming soon.</p>

      </div>
    </section>
  );
};

export default Projects;