import React from 'react';
import './About.css';

const About = () => {
  return (
    <section id="about" className="about">
      <h2>About This Site</h2>
      <p>Welcome! Recently, I made the switch from an academic career to the world of IT, which naturally called for creating my own website.</p>
      <br />
      <p>I'm fascinated by the seemingly unlimited potential that software development brings, both in scope and creativity. This site serves as a central hub to share my passion projects, where I build whatever captures my interest at the moment.</p>
      <br />
      <p>In this age of information overload, I find myself with numerous unsorted thoughts about the ever-changing tech landscape and the world at large. To bring order to this chaos, I'll be writing blog-like posts and deep dives into the most intriguingly esoteric topics.</p>
      <br />
      <p>Consider this space a curated online journal - a blend of practical projects, thought experiments, and explorations into the wonderfully weird corners of technology and science.</p>
    </section>
  );
};

export default About;