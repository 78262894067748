import React from 'react';
import './Blog.css';

const Blog = () => {
  return (
    <section id="blog" className="blog">
      <h2>Posts</h2>
      <div className="blog-grid">
        {/* Add your blog post previews here */}
        {/* <div className="blog-post">Blog Post 1</div>
        <div className="blog-post">Blog Post 2</div>
        <div className="blog-post">Blog Post 3</div> */}
        <p>Coming soon.</p>
      </div>
    </section>
  );
};

export default Blog;